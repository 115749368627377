import React from 'react'

type IIconProps = {
  color?: string
}

const FacebookIcon: React.FC<React.SVGProps<SVGSVGElement> & IIconProps> = ({
  color = 'currentColor',
  ...rest
}) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 8.12 17" {...rest}>
      <path
        fill={color}
        d="M8.12,5.51H5.36V3.74a.73.73,0,0,1,.77-.82h2V0H5.39A3.35,3.35,0,0,0,1.73,3.56V5.51H0v3H1.73V17H5.36V8.51H7.81Z"
      />
    </svg>
  )
}

export default FacebookIcon
